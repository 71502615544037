<template>
  <div id="NewsList">
    <div id="YCF-top">
      <div class="top-titles">
        <h1>
          <b v-html="TopTitles.title"></b>
          <br />
          <small v-html="TopTitles.second_title"></small>
        </h1>
      </div>
      <div class="background-group">
        <div
          class="bg active"
          :style="{ backgroundImage: 'url(' + TopCovers[0] + ')' }"
          style="background-size: cover"
        ></div>
      </div>
    </div>

    <div id="YCF-news-list" class="container-fluid">
      <div class="row">
        <ol class="news">
          <li
            v-for="(item, item_index) in NewsList"
            :key="'list_' + item_index"
          >
            <div class="news-tag">
              <span class="tag">{{ item.no }}</span>
              <span class="owner">{{ item.owner }}</span>
            </div>
            <div class="news-title">
              <router-link :to="'/NewsPage/' + item.article_id">{{
                item.project_name
              }}</router-link>
            </div>
          </li>
        </ol>

        <ul class="pagination">
          <li>
            <router-link
              class="Pre"
              v-if="$route.params.id != 1"
              :to="'/News/' + ($route.params.id - 1)"
            >
              <i class="fas fa-chevron-left"></i>
            </router-link>

            <router-link class="Pre" v-else :to="'/News/' + $route.params.id">
              <i class="fas fa-chevron-left"></i>
            </router-link>
          </li>
          <li v-for="item in TotalPage" :key="item">
            <router-link
              :to="'/News/' + item"
              :class="{ active: item == $route.params.id }"
              >{{ item }}</router-link
            >
          </li>
          <li>
            <router-link
              class="Pre"
              v-if="$route.params.id != TotalPage"
              :to="'/News/' + (parseInt($route.params.id) + 1)"
            >
              <i class="fas fa-chevron-right"></i>
            </router-link>

            <router-link class="Pre" v-else :to="'/News/' + $route.params.id">
              <i class="fas fa-chevron-right"></i>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { ReturnErrorPage } from '@/common/prerender_event'
import { GetMetaData } from '@/common/meta.js'
import qs from 'qs'
export default {
  name: 'newslist',
  data() {
    return {
      TopTitles: {
        title: '工程實績',
        second_title: '認真、專業、負責、守信',
      },
      TopCovers: {
        0: '/img/index-header/news_cover.jpg',
      },
      NewsList: [],
      TotalPage: 0,
      meta_data: null,
    }
  },
  methods: {
    GetNewsData() {
      this.axios
        .post(
          'https://www.dingchong.com.tw/php/GetNewsList.php',
          qs.stringify({ page: this.$route.params.id })
        )
        .then((response) => {
          if (response.data == '0 results') {
            ReturnErrorPage()
          } else {
            this.NewsList = response.data.data
            this.TotalPage = response.data.total_page
            this.meta_data = GetMetaData(
              '工程實績',
              '各項工程監測系統規劃分析與評估、監測器材經銷業務、工程作業規劃與技術諮詢顧問。重視監測品質的專業監測工程公司，認真、專業、負責、守信是我們的座右銘。',
              '/img/index-header/news_cover.jpg'
            )
            this.$nextTick(() => {
              window.prerenderReady = true
            })
          }
        })
    },
  },
  metaInfo() {
    return this.meta_data
  },
  created() {
    this.GetNewsData()
  },
  watch: {
    '$route.params.id': function () {
      this.GetNewsData()
    },
  },
}
</script>
